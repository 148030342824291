import styles from './Footer.module.css';

const Footer = () => {
    return (
        <div className={styles.Footer}>
            <p>Made with <span >&hearts;</span> By Abdul Zaker Olive</p>
        </div>
    )
}

export default Footer;
